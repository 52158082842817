import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from '../../../enums';
import { globalStyles } from '../../../helper/GlobalStyles';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUserInformation, selectWindowData, setUserInformation } from '../../../store/reducers';
import { Button, InputField, Label, Loading, PickerField } from '../../atoms';
import InputLoader from '../../atoms/InputLoader/InputLoader';
import { Header } from '../../organisms/Header/Header';
import { styles as styleFn } from './UpdatePersonalData.style';
import './UpdatePersonalData.css';
import classes from './UpdatePersonalData.module.scss';

export function UpdatePersonalData() {
  const navigate = useNavigate();
  const { isMobile, isTablet, isDesktop } = useAppSelector(selectWindowData);
  const styles = styleFn(isDesktop);

  const userInformation = useAppSelector(selectUserInformation);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState<boolean>(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [addressID, setAddressID] = useState(0);
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [zip, setZip] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState({
    firstnameEmpty: false,
    lastnameEmpty: false,
    streetEmpty: false,
    houseNumberEmpty: false,
    zipEmpty: false,
    locationEmpty: false,
    countryEmpty: false,
    phoneEmpty: false,
    mobileShort: false,
    mobileLong: false,
    emailEmpty: false,
    emailCorrect: false,
  });
  const [initialState, setInitialState] = useState({
    email: '',
    mobile: '',
    addressId: -1,
    street: '',
    postcode: '',
    houseNumber: '',
    country: '',
    city: '',
  });

  const getUserData = () => {
    const information = userInformation;

    setEmail(information.email);
    setMobileNumber(information.mobile);
    setAddressID(information.address[0].id!);
    setStreet(information.address[0].street);
    setZip(information.address[0].postcode);
    setHouseNumber(information.address[0].houseNumber);
    setCountry(information.address[0].country);
    setLocation(information.address[0].city);

    setInitialState({
      email: information.email,
      mobile: information.mobile,
      addressId: information.address[0].id!,
      street: information.address[0].street,
      postcode: information.address[0].postcode,
      houseNumber: information.address[0].houseNumber,
      country: information.address[0].country,
      city: information.address[0].city,
    });
  };

  const mailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validate = () => {
    const newError = {
      streetEmpty: street === '',
      houseNumberEmpty: houseNumber === '',
      zipEmpty: zip === '',
      locationEmpty: location === '',
      countryEmpty: country === '',
      phoneEmpty: mobileNumber === '',
      mobileShort: mobileNumber.length < 6 && mobileNumber !== '',
      mobileLong: mobileNumber.length > 17,
      emailEmpty: email === '',
      emailCorrect: email === '' ? false : !mailRegex.test(email),
    };
    // @ts-expect-error setError must work
    setError(newError);

    return newError;
  };

  const areValuesTheSame = () =>
    initialState.email === email &&
    initialState.mobile === mobileNumber &&
    initialState.addressId === addressID &&
    initialState.street === street &&
    initialState.postcode === zip &&
    initialState.houseNumber === houseNumber &&
    initialState.country === country &&
    initialState.city === location;

  const save = async () => {
    const newError = validate();
    if (Object.values(newError).find((e) => e)) {
      return;
    }
    if (areValuesTheSame()) {
      setSaveSuccess(false);
      navigate('/my-account');

      return;
    }

    const data = {
      email,
      mobile: mobileNumber,
      address: [
        {
          id: addressID,
          street,
          postcode: zip,
          houseNumber,
          country,
          city: location,
        },
      ],
    };

    setSaving(true);
    const response = await UserService.updateUserInfo(data);
    setSaving(false);

    if (response.error) {
      console.error(response.message);
    }
    if (!response.error) {
      const newUserInformation = { ...userInformation };
      newUserInformation.email = data.email;
      newUserInformation.mobile = data.mobile;
      newUserInformation.address = [...data.address];
      setSaveSuccess(true);
      dispatch(setUserInformation(newUserInformation));
      setTimeout(() => {
        setSaveSuccess(false);
        navigate('/my-account');
      }, 1000);
    }
  };

  useEffect(() => {
    if (userInformation.id !== '') {
      setLoading(true);
      getUserData();
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userInformation.id !== '') {
      setLoading(true);
      getUserData();
      setLoading(false);
    }
  }, [userInformation]);

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Header />
      <div className={`${classes.UpdatePersonalData} UpdatePersonalData`} style={styles.scrollview}>
        <div className={classes.container} style={styles.container}>
          {false && <Label style={[styles.heading1]}>Kontaktdaten bearbeiten</Label>}
          <div className='text' style={styles.heading2}>
            Adresse
          </div>
          <div className='text' style={styles.descriptionText}>
            Bitte geben Sie Ihre Meldeadresse unbedingt vollständig und korrekt an.
          </div>
          <div className='input-row' style={globalStyles.row}>
            <div
              className={classes['input-container']}
              style={mergeStyles([
                isMobile || isTablet ? styles.marginRow : {},
                isMobile || isTablet ? globalStyles.flex5 : {},
                globalStyles.marginRight10,
              ])}
            >
              <Label style={[styles.label]}>Straße</Label>
              <InputField
                hasError={error.streetEmpty}
                onChange={setStreet}
                value={street}
                style={isMobile || isTablet ? styles.input : undefined}
              />
              {error.streetEmpty && (
                <div className='text error-text' style={styles.errorText}>
                  {ErrorMessages.EMPTY_FIELD}
                </div>
              )}
            </div>

            <div
              className={classes['input-container']}
              style={mergeStyles([
                isMobile || isTablet ? styles.marginRow : {},
                isMobile || isTablet ? globalStyles.flex3 : {},
                globalStyles.marginLeft10,
              ])}
            >
              <Label style={[styles.label]}>Hausnummer</Label>
              <InputField
                hasError={error.houseNumberEmpty}
                onChange={setHouseNumber}
                value={houseNumber}
                style={isMobile || isTablet ? [styles.input] : [styles.houseNumberInputField]}
              />
              {error.houseNumberEmpty && (
                <div>
                  <div
                    className='text error-text'
                    style={mergeStyles([styles.errorText, isDesktop ? styles.width250 : styles.bottomMinus38])}
                  >
                    {ErrorMessages.EMPTY_FIELD}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={globalStyles.column}>
            <div
              className='second-input-row'
              style={mergeStyles([globalStyles.row, error.zipEmpty && !isDesktop ? globalStyles.marginBottom40 : {}])}
            >
              <div
                className={classes['input-container']}
                style={mergeStyles([
                  isMobile || isTablet ? styles.marginRow : {},
                  globalStyles.flex2,
                  globalStyles.marginRight10,
                ])}
              >
                <Label style={[styles.label]}>PLZ</Label>
                <InputField
                  hasError={error.zipEmpty}
                  onChange={setZip}
                  value={zip}
                  style={isMobile || isTablet ? [styles.input] : undefined}
                />
                {error.zipEmpty && (
                  <div>
                    <div className='text error-text' style={mergeStyles([styles.errorText, styles.bottomMinus38])}>
                      {ErrorMessages.EMPTY_FIELD}
                    </div>
                  </div>
                )}
              </div>
              <div
                className={classes['input-container']}
                style={mergeStyles([
                  isMobile || isTablet ? styles.marginRow : {},
                  globalStyles.flex4,
                  globalStyles.marginLeft10,
                ])}
              >
                <Label style={[styles.label]}>Ort</Label>
                <InputField
                  hasError={error.locationEmpty}
                  onChange={setLocation}
                  value={location}
                  style={isMobile || isTablet ? [styles.input] : undefined}
                />
                {error.locationEmpty && (
                  <div className='text error-text' style={styles.errorText}>
                    {ErrorMessages.EMPTY_FIELD}
                  </div>
                )}
              </div>
            </div>
            <div className={classes['input-container']}>
              <Label style={[styles.label]}>Land</Label>
              <PickerField
                style={isMobile || isTablet ? [styles.input] : [styles.countryInputField]}
                value={country}
                hasError={error.countryEmpty}
                onChange={setCountry}
                disableFirstLabel={country !== ''}
                items={[
                  { label: 'Deutschland', value: 'germany' },
                  { label: 'Österreich', value: 'austria' },
                  { label: 'Schweiz', value: 'switzerland' },
                  { label: 'Belgien', value: 'belgium' },
                  { label: 'Dänemark', value: 'denmark' },
                  { label: 'Finnland', value: 'finland' },
                  { label: 'Frankreich', value: 'france' },
                  { label: 'Griechenland', value: 'greece' },
                  { label: 'Italien', value: 'italy' },
                  { label: 'Niederlande', value: 'netherlands' },
                  { label: 'Norwegen', value: 'norway' },
                  { label: 'Polen', value: 'poland' },
                  { label: 'Portugal', value: 'portugal' },
                  { label: 'Schweden', value: 'sweden' },
                  { label: 'Spanien', value: 'spain' },
                  { label: 'Tschechien', value: 'czechRepublic' },
                  { label: 'Türkei', value: 'turkey' },
                  { label: 'USA', value: 'unitedStates' },
                  { label: 'Vereinigtes Königreich', value: 'unitedKingdom' },
                ]}
              />
            </div>
            {error.countryEmpty && (
              <div className='text error-text' style={styles.errorText}>
                {ErrorMessages.EMPTY_FIELD}
              </div>
            )}
          </div>
          <Label style={[styles.heading2]}>Telefon / E-Mail</Label>
          <div className='text' style={styles.descriptionText}>
            Wie können wir Sie erreichen? Sie können eine mobile und eine Festnetznummer hinterlegen oder nur eine von
            beiden.
          </div>

          <div className='input-row' style={isDesktop ? globalStyles.row : globalStyles.column}>
            <div className={classes['input-container']} style={isMobile || isTablet ? styles.marginRow : undefined}>
              <Label style={[styles.label]}>Telefon Mobil</Label>
              <InputField
                hasError={error.phoneEmpty || error.mobileShort || error.mobileLong}
                onChange={setMobileNumber}
                value={mobileNumber}
                isTel={true}
                style={isMobile || isTablet ? styles.input : undefined}
              />
              {(error.phoneEmpty || error.mobileShort || error.mobileLong) && (
                <div className='text error-text' style={styles.errorText}>
                  {error.phoneEmpty && ErrorMessages.PHONE_EMPTY}
                  {error.mobileShort && ErrorMessages.PHONE_SHORT}
                  {error.mobileLong && ErrorMessages.PHONE_LONG}
                </div>
              )}
            </div>
          </div>

          <div className={`${classes['email-field-container']} ${classes['input-container']}`} style={styles.marginRow}>
            <Label style={[styles.label]}>E-Mail-Adresse</Label>
            <InputField
              hasError={error.emailEmpty || error.emailCorrect}
              onChange={setEmail}
              value={email}
              isEditable={false}
              style={isMobile || isTablet ? styles.input : {}}
            />
            {error.emailEmpty && (
              <div className='text error-text' style={styles.errorText}>
                {ErrorMessages.EMPTY_FIELD}
              </div>
            )}
            {error.emailCorrect && (
              <div className='text error-text' style={styles.errorText}>
                {ErrorMessages.EMAIL_REQUIREMENTS}
              </div>
            )}
          </div>

          {saveSuccess && (
            <div style={isDesktop ? undefined : mergeStyles([globalStyles.row, styles.saveMessageContainer])}>
              <div style={styles.saveMessageText}>Speichern erfolgreich</div>
            </div>
          )}
          <div
            className={classes['button-container']}
            style={mergeStyles([
              globalStyles.row,
              styles.marginRow,
              styles.buttonContainer,
              isSaving ? { cursor: 'not-allowed ' } : {},
            ])}
          >
            <Button
              style={[isSaving ? styles.buttonLoading : styles.button, globalStyles.saveButtonContainer]}
              onClick={save}
            >
              {isSaving ? <InputLoader /> : null}
              <div className='text' style={styles.buttonText}>
                Speichern
              </div>
            </Button>
            {Object.values(error).find((e) => e) && (
              <div className={`${classes['save-error']} text`} style={styles.errorText}>
                {ErrorMessages.ERROR}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
