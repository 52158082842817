import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Icon } from '../../../atoms';
import style from './StageElements.module.scss';

type PharmacyProps = {
  pharmacy: any;
};

const cx = classNames.bind(style);

const pharmacyContainerClasses = cx({ PharmacyContainer: true });
const medicationPharmacyIconClasses = cx({ MedicationPharmacyIcon: true });
const titleClasses = cx({ DescriptionTitle: true });
const pharmacyNameClasses = cx({ PharmacyName: true });
const pharmacyNoticeClasses = cx({ PharmacyNotice: true });

export const PharmacyInfo = (props: PharmacyProps) => {
  const navigate = useNavigate();
  const { pharmacy } = props;

  return (
    <div className={pharmacyContainerClasses}>
      <div className={medicationPharmacyIconClasses}>
        <Icon icon='pharmacy' size={45} color={'#3db384'} />
      </div>
      <div className={titleClasses}>Meine Apotheke</div>
      <div className={pharmacyNameClasses}>
        {pharmacy?.name ? pharmacy.name : `Sie haben noch keine Apotheke gewählt.`}
      </div>
      <div className={pharmacyNoticeClasses}>
        Bitte stellen Sie sicher, dass Ihre Medikation bei Ihrer Apotheke verfügbar ist.
      </div>
      <div>
        <Button onClick={() => navigate('/my-pharmacy')} secondary={true} small={true} inlineBlock={true}>
          {pharmacy?.name ? `Apotheke ändern` : `Apotheke wählen`}
        </Button>
      </div>
    </div>
  );
};
