import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

export const Questionnaire = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <Icon icon='questionnaire' size={80} color={'white'} />
        <div className={classes.CircleText}>
          Therapie
          <br />
          beginnen
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>Therapie beginnen</div>
        <div className={classes.DescriptionText}>
          Bevor Sie Ihre Therapie starten können, benötigen wir noch ein paar Informationen von Ihnen
        </div>
        <div className={classes.ActionButtons}>
          <Button onClick={() => navigate('/questionnaire/step-1')}>Angaben vervollständigen</Button>
        </div>
      </div>
    </>
  );
};
