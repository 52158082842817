import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { Appointment } from '../../../../types/ninox.types';
import { Button, Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

type BookFollowUpAppointmentProps = {
  allAppointments: Appointment[];
};

export const BookFollowUpAppointment = (props: BookFollowUpAppointmentProps) => {
  const navigate = useNavigate();

  const firstAppointments = props.allAppointments.filter((a) => String(a.appointmentType) === '1');
  // appointments are sorted by date. If we should have more than one, the last element is the most recent
  if (firstAppointments.length === 0) {
    return (
      <>
        <div>Etwas ist schiefgelaufen. Bitte kontaktieren Sie den Support (NO_FIRST_APPOINTMENT_FOUND)</div>
      </>
    );
  }
  const latestFirstAppointment = firstAppointments[firstAppointments.length - 1];
  const wasFirstAppointmentOnSite = String(latestFirstAppointment.type) === '2';

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <Icon icon='calendarWithDays' size={70} color={'white'} />
        <div className={classes.CircleText}>
          {wasFirstAppointmentOnSite === true && `Folgetermin`}
          {wasFirstAppointmentOnSite !== true && `Videosprechstunde`}
          <br />
          buchen
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>
          {wasFirstAppointmentOnSite === true && `Buchen Sie jetzt Ihren Folgetermin`}
          {wasFirstAppointmentOnSite !== true && `Buchen Sie jetzt Ihre Videosprechstunde`}
        </div>
        <div className={classes.DescriptionText}>
          {wasFirstAppointmentOnSite === true &&
            `Der Folgetermin kann in Ihrem nowomed Therapiezentrum oder online stattfinden.`}
          {wasFirstAppointmentOnSite !== true && `Planen Sie ausreichend Zeit für Bearbeitung und Rezeptversand ein.`}
        </div>
        <div className={classes.ActionButtons}>
          {wasFirstAppointmentOnSite === true && (
            <Button
              onClick={() =>
                navigate('/profile/appointment-choice', { state: { firstAppointment: latestFirstAppointment } })
              }
            >
              Jetzt Termin buchen
            </Button>
          )}
          {wasFirstAppointmentOnSite !== true && (
            <Button onClick={() => navigate('/profile/online-appointment')}>Jetzt Termin buchen</Button>
          )}
        </div>
      </div>
    </>
  );
};
