import React from 'react';
import { Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

export const PatientNotSuitable = () => {
  return (
    <>
      <div className={classes.CircleDeclined}>
        <Icon icon='cross' size={80} color={'white'} />
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitleOrange}>Beratungstermin nicht möglich</div>
        <div className={classes.DescriptionText}>
          Wir müssen Ihnen leider mitteilen, dass wir Ihnen zurzeit keinen telemedizinischen Beratungstermin anbieten
          können.
        </div>
        <div className={classes.DescriptionText}>
          Dies beruht auf einer sorgfältigen Abwägung aller relevanten Faktoren. Sowohl die Telemedizin als auch die
          Cannabistherapie sind spezielle Behandlungsformen, die nicht in jedem Fall zur Verfügung stehen.
        </div>
        <div className={classes.DescriptionText}>
          Wir empfehlen Ihnen, die nächsten Schritte und möglichen Therapieoptionen mit einem in der Cannabismedizin
          geschulten Arzt vor Ort zu besprechen, oder mit Ihrem behandelnden Arzt.
        </div>
      </div>
    </>
  );
};
