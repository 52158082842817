import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { differenceInYears, parse } from 'date-fns';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserInformation } from '../../../../store/reducers';
import { Button, Headline, InputField, Label, PickerField } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { Header } from '../../../organisms';
import { countryOptions, genderOptions } from './PickerFieldOptions';
import style from './Step7.module.scss';

const cx = classNames.bind(style);
export function Step7(): JSX.Element {
  const navigation = useNavigate();
  const userInformation = useAppSelector(selectUserInformation);

  const [loading, setLoading] = useState<boolean>(true);
  const [firstName, setFirstName] = useState(userInformation?.firstname || '');
  const [lastName, setLastName] = useState(userInformation?.lastname || '');
  const [gender, setGender] = useState(userInformation.sex || '');
  const [birthday, setBirthday] = useState(userInformation.birthday || '');
  const [nationality, setNationality] = useState(userInformation.nationality || '');
  const [preferredLanguage, setPreferredLanguage] = useState(userInformation.language || '');
  const [insurance, setInsurance] = useState(userInformation.insurance || '');
  const [street, setStreet] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [zip, setZip] = useState('');
  const [location, setLocation] = useState('');
  const [country, setCountry] = useState('');
  const [birthdayError, setBirthdayError] = useState('');

  useEffect(() => {
    const step6 = sessionStorage.getItem('questionnaire-step6');
    if (!step6) {
      navigation('/questionnaire/step-6');
    }
    const personalData = sessionStorage.getItem('questionnaire-personalData');
    if (personalData !== null) {
      const parsedData = JSON.parse(personalData);
      setFirstName(parsedData.firstName);
      setLastName(parsedData.lastName);
      setGender(parsedData.sex);
      setBirthday(parsedData.birthday);
      setNationality(parsedData.nationality);
      setPreferredLanguage(parsedData.language);
      setInsurance(parsedData.insurance);
    }
    const addressData = sessionStorage.getItem('questionnaire-addressData');
    if (addressData !== null) {
      const parsedData = JSON.parse(addressData);
      setStreet(parsedData.street);
      setHouseNumber(parsedData.houseNumber);
      setZip(parsedData.postcode);
      setLocation(parsedData.city);
      setCountry(parsedData.country);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    let error = '';
    if (birthday !== '') {
      const date = parse(birthday, 'yyyy-MM-dd', new Date());
      const age = differenceInYears(new Date(), date);
      if (age < 18) {
        error = 'Sie müssen mindestens 18 Jahre alt sein um eine Therapie bei nowomed zu beginnen.';
      }
    }
    setBirthdayError(error);
  }, [birthday]);

  const isButtonDisabled =
    firstName === '' ||
    lastName === '' ||
    gender === '' ||
    birthday === '' ||
    // nationality === '' ||
    // preferredLanguage === '' ||
    // insurance === '' ||
    street === '' ||
    houseNumber === '' ||
    zip === '' ||
    location === '' ||
    country === '' ||
    birthdayError !== '';

  const setPersonalData = () => {
    const personalData = {
      firstName,
      lastName,
      sex: gender,
      birthday,
      nationality,
      language: preferredLanguage,
      insurance,
    };
    const addressData = {
      street,
      houseNumber,
      postcode: zip,
      city: location,
      country,
    };

    sessionStorage.setItem('questionnaire-personalData', JSON.stringify(personalData));
    sessionStorage.setItem('questionnaire-addressData', JSON.stringify(addressData));

    navigation('/questionnaire/step-8');
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const dividerContainerClasses = cx('flex', 'flex-center', 'mb-3', 'mt-3');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');
  const errorTextClasses = cx('text', { ErrorText: true });

  return (
    <>
      <Header barTitle='Daten vervollständigen' showBackButton={true} />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={7} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Persönliche Daten</Headline>
        </div>
        {!loading && (
          <>
            <div className={questionContainerClasses}>
              <div>
                <Label>Vorname</Label>
              </div>
              <div>
                <InputField value={firstName} onChange={setFirstName} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Nachname</Label>
              </div>
              <div>
                <InputField value={lastName} onChange={setLastName} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Geschlecht</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={genderOptions}
                  value={gender}
                  onChange={setGender}
                />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Geburtstag</Label>
              </div>
              <div>
                <InputField type={'Date'} onChange={setBirthday} value={birthday} hasError={birthdayError !== ''} />
              </div>
              <div>{birthdayError !== '' && <div className={errorTextClasses}>{birthdayError}</div>}</div>
            </div>
            {/*
            <div className={questionContainerClasses}>
              <div>
                <Label>Nationalität</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={nationalityOptions}
                  value={nationality}
                  onChange={setNationality}
                />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Bevorzugte Sprache</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={preferredLanguageOptions}
                  value={preferredLanguage}
                  onChange={setPreferredLanguage}
                />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Versicherung</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={insuranceOptions}
                  value={insurance}
                  onChange={setInsurance}
                />
              </div>
            </div>
        */}
            <div className={dividerContainerClasses}></div>
            <Headline level={3}>Ihre Anschrift</Headline>
            <div className={questionContainerClasses}>
              <div>
                <Label>Straße</Label>
              </div>
              <div>
                <InputField value={street} onChange={setStreet} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Hausnummer</Label>
              </div>
              <div>
                <InputField value={houseNumber} onChange={setHouseNumber} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>PLZ</Label>
              </div>
              <div>
                <InputField type='number' isNumber={true} value={zip} onChange={setZip} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Ort</Label>
              </div>
              <div>
                <InputField value={location} onChange={setLocation} />
              </div>
            </div>
            <div className={questionContainerClasses}>
              <div>
                <Label>Land</Label>
              </div>
              <div>
                <PickerField
                  disableFirstLabel={false}
                  firstLabel='Bitte wählen'
                  items={countryOptions}
                  value={country}
                  onChange={setCountry}
                />
              </div>
            </div>
            <div className={buttonContainerClasses}>
              <Button disabled={isButtonDisabled} onClick={setPersonalData}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
