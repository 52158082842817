// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessages } from '../../../../enums';
import { TERM } from '../../../../enums/term.enum';
import { globalStyles } from '../../../../helper/GlobalStyles';
import { mergeStyles } from '../../../../helper/UtilityFunctions';
import UserService from '../../../../services/User.service';
import { useAppSelector } from '../../../../store/hooks';
import { selectAuth, selectTerms, selectWindowData } from '../../../../store/reducers';
import { Button, Headline, InputField, Label, Modal } from '../../../atoms';
import InputLoader from '../../../atoms/InputLoader/InputLoader';
import { Checkbox } from '../../../molecules/Checkbox/Checkbox';
import { BaseHeader } from '../../../organisms/BaseHeader/BaseHeader';
import { styles as inlineStyles } from './RegisterProcedure.style';
import styles from './RegisterProcedure.module.scss';

export function RegisterProcedure() {
  const navigate = useNavigate();

  // STATE MANAGEMENT
  const auth = useAppSelector(selectAuth);
  const { isMobile, isTablet, isPhablet, isDesktop, isMobileOrTablet } = useAppSelector(selectWindowData);
  const terms = useAppSelector(selectTerms);

  // FIELDS
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cannabisPatient, setCannabisPatient] = useState<number>(0);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const [agbs, setAgbs] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [dataProtection, setDataProtection] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [morning, setMorning] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [noon, setNoon] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [afternoon, setAfternoon] = useState('');

  const [modalHeadline, setModalHeadline] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  // UTILITY
  const [isSaving, setSaving] = useState<boolean>(false);
  const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;
  // eslint-disable-next-line no-useless-escape
  const mailRegex = /^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // VALIDATION
  const [error, setError] = useState({
    cannabisPatientEmpty: false,
    firstnameEmpty: false,
    lastnameEmpty: false,
    emailEmpty: false,
    emailCorrect: false,
    emailTaken: false,
    phoneEmpty: false,
    phoneShort: false,
    phoneLong: false,
    passwordReq: false,
    passwordEqual: false,
    agbs: false,
    dataProtection: false,
  });

  // === STATE SECTION START ===
  useEffect(() => {
    if (auth.isAuthenticated) {
      // navigate('/my-account');
    }
  }, [auth.isAuthenticated]);
  // === STATE SECTION END ===

  // === FUNCTIONALITY START ===
  const validate = async () => {
    const newError = {
      cannabisPatientEmpty: cannabisPatient === -1,
      firstnameEmpty: firstname === '',
      lastnameEmpty: lastname === '',
      emailEmpty: email === '',
      emailCorrect: email === '' ? false : !mailRegex.test(email),
      emailTaken: false,
      phoneEmpty: phoneNumber === '',
      phoneShort: phoneNumber.length < 6 && phoneNumber !== '',
      phoneLong: phoneNumber.length > 17,
      passwordReq: !passwordRegex.test(password) || password.length < 8,
      passwordEqual: password !== repeatedPassword,
      agbs: !agbs,
      dataProtection: !dataProtection,
    };

    setError(newError);

    if (newError.emailEmpty === false && newError.emailCorrect === false) {
      const resp = await UserService.checkEmail({ email });
      if (resp.status !== 200 && resp.status === 409) {
        setError({ ...newError, emailTaken: true });
        newError.emailTaken = true;
      }
    }

    return newError;
  };

  const save = async () => {
    const newError = await validate();

    if (Object.values(newError).every((value) => value === false)) {
      const registerData = {
        patient: {
          firstname,
          lastname,
          email,
          mobile: phoneNumber,
          phoneAvailabilities: [morning, noon, afternoon].filter(Boolean).join(', '),
          isAlreadyCannabisPatient: Boolean(cannabisPatient),
        },
        password,
        isNewsletterAgree: newsletter,
      };

      if (!isSaving) {
        setSaving(true);

        try {
          const resp = await UserService.register(registerData);
          if (!resp.error) {
            navigate({ pathname: '/register/success', search: `?email=${email}` });
          }
          setSaving(false);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const openModal = (term: TERM) => {
    const content = terms.find((t) => t.type === term)?.termText || '';
    const headline = terms.find((t) => t.type === term)?.headline || '';
    setModalContent(content);
    setModalHeadline(headline);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalHeadline('');
    setModalContent('');
    setModalVisible(false);
  };

  // === FUNCTIONALITY END ===

  return (
    <React.Fragment>
      <Modal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        cb={closeModal}
        disableSecondaryButton={true}
        primaryButtonText='Schließen'
      >
        <Headline className={styles.ModalHeadline} level={2}>
          {modalHeadline}
        </Headline>
        <div className={styles.ModalContent} dangerouslySetInnerHTML={{ __html: modalContent }}></div>
      </Modal>
      <BaseHeader />
      <div className={styles.RegisterProcedure} style={{ backgroundColor: 'white' }}>
        <div className={styles.container} style={inlineStyles.container}>
          <div className={`${styles['first-headline']} text`} style={inlineStyles.heading3}>
            Kostenfreie Therapieanfrage stellen *
          </div>
          <div>
            {/* NAME & SURNAME */}
            <div
              className={styles['name-container']}
              style={error.firstnameEmpty || error.lastnameEmpty ? globalStyles.marginBottom25 : undefined}
            >
              <div style={{ flexDirection: 'row', width: '100%' }}>
                {/* NAME */}
                <div
                  className={styles['input-field']}
                  style={mergeStyles([
                    isMobile || isTablet
                      ? error.firstnameEmpty
                        ? globalStyles.marginBottom30
                        : globalStyles.marginBottom10
                      : globalStyles.flex2,
                    globalStyles.marginRight10,
                    globalStyles.flex2,
                  ])}
                >
                  <Label style={[inlineStyles.label]}>Vorname</Label>
                  <InputField
                    hasError={error.firstnameEmpty}
                    onChange={setFirstname}
                    value={firstname}
                    style={mergeStyles([
                      globalStyles.marginRight25,
                      isMobile || isTablet ? inlineStyles.input : {},
                      isDesktop ? globalStyles.marginRight0 : {},
                    ])}
                  />
                  <div>
                    {error.firstnameEmpty && (
                      <div
                        style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5, { maxWidth: '36.56vw' }])}
                      >
                        {ErrorMessages.EMPTY_FIELD}
                      </div>
                    )}
                  </div>
                </div>

                {/* SURNAME */}
                <div
                  style={mergeStyles([
                    isMobile || isTablet
                      ? error.lastnameEmpty
                        ? globalStyles.marginBottom30
                        : globalStyles.marginBottom10
                      : globalStyles.flex2,
                    globalStyles.flex2,
                    globalStyles.marginLeft10,
                  ])}
                >
                  <Label style={[inlineStyles.label]}>Nachname</Label>
                  <InputField
                    hasError={error.lastnameEmpty}
                    onChange={setLastname}
                    value={lastname}
                    style={isMobile || isTablet ? inlineStyles.input : undefined}
                  />
                  <div>
                    {error.lastnameEmpty && (
                      <div
                        style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5, { maxWidth: '36.56vw' }])}
                      >
                        {ErrorMessages.EMPTY_FIELD}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* EMAIL & TELEPHONE */}
            <div
              style={
                isDesktop
                  ? mergeStyles([globalStyles.marginBottom30, globalStyles.row, globalStyles.marginTop10])
                  : error.emailEmpty || error.emailCorrect || error.phoneEmpty || error.phoneShort || error.phoneLong
                    ? globalStyles.marginBottom25
                    : undefined
              }
            >
              <div
                className={styles['email-container']}
                style={
                  isMobile || isTablet
                    ? error.emailEmpty || error.emailCorrect || error.emailTaken
                      ? mergeStyles([globalStyles.marginBottom30, globalStyles.marginTop10])
                      : mergeStyles([globalStyles.marginBottom10, globalStyles.marginTop10])
                    : isPhablet
                      ? error.emailEmpty || error.emailCorrect || error.emailTaken
                        ? globalStyles.marginBottom40
                        : globalStyles.marginBottom25
                      : undefined
                }
              >
                <Label style={[inlineStyles.label]}>E-Mail-Adresse</Label>
                <InputField
                  hasError={error.emailEmpty || error.emailCorrect}
                  onChange={setEmail}
                  isEmail={true}
                  value={email}
                  style={[
                    !isPhablet ? globalStyles.marginRight25 : {},
                    globalStyles.marginBottom10,
                    isMobile || isTablet ? inlineStyles.input : {},
                    isDesktop ? globalStyles.marginRight0 : {},
                    isMobile || isTablet ? globalStyles.marginBottom0 : {},
                    isPhablet ? globalStyles.marginBottom0 : {},
                    isDesktop ? globalStyles.marginBottom0 : {},
                  ]}
                />
                <div>
                  {error.emailEmpty && (
                    <div style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5])}>
                      {ErrorMessages.EMPTY_FIELD}
                    </div>
                  )}
                </div>
                <div>
                  {error.emailCorrect && (
                    <div style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5])}>
                      {ErrorMessages.EMAIL_REQUIREMENTS}
                    </div>
                  )}
                </div>
                <div>
                  {error.emailTaken && (
                    <div style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5])}>
                      {ErrorMessages.EMAIL_TAKEN}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={styles['phone-container']}
                style={
                  isMobile || isTablet
                    ? error.phoneEmpty || error.phoneShort || error.phoneLong
                      ? mergeStyles([globalStyles.marginBottom30, globalStyles.marginTop10])
                      : undefined
                    : isPhablet
                      ? globalStyles.marginBottom25
                      : undefined
                }
              >
                <Label style={[inlineStyles.label]}>Telefonnummer</Label>
                <InputField
                  hasError={error.phoneEmpty || error.phoneShort || error.phoneLong}
                  onChange={setphoneNumber}
                  value={phoneNumber}
                  isTel={true}
                  style={[
                    isMobile || isTablet ? inlineStyles.input : inlineStyles.phoneField,
                    isDesktop ? globalStyles.marginBottom0 : {},
                  ]}
                />
                <div>
                  {(error.phoneEmpty || error.phoneShort || error.phoneLong) && (
                    <div
                      style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5, { maxWidth: '36.56vw' }])}
                    >
                      {error.phoneEmpty && ErrorMessages.PHONE_EMPTY}
                      {error.phoneShort && ErrorMessages.PHONE_SHORT}
                      {error.phoneLong && ErrorMessages.PHONE_LONG}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* PASSWORD & REPEAT PASSWORD */}
          <div className={styles.container} style={mergeStyles([inlineStyles.container, globalStyles.marginBottom12])}>
            <div className='text' style={mergeStyles([inlineStyles.passwordLabel, globalStyles.marginVertical25])}>
              Wählen Sie das Passwort für Ihren kostenfreien Therapie-Account
            </div>
            <div style={isDesktop ? mergeStyles([globalStyles.marginBottom30, globalStyles.row]) : undefined}>
              {/* PASSWORD */}
              <div
                className={styles['password-container']}
                style={mergeStyles([
                  isMobile || isTablet ? globalStyles.column : {},
                  error.passwordReq ? globalStyles.marginBottom10 : globalStyles.marginBottom10,
                  isPhablet ? (error.passwordReq ? globalStyles.marginBottom40 : globalStyles.marginBottom25) : {},
                ])}
              >
                <Label style={[inlineStyles.label]}>Passwort</Label>
                {/* <View style={globalStyles.flex1}> */}
                <InputField
                  hasError={error.passwordReq}
                  onChange={setPassword}
                  secure={true}
                  value={password}
                  style={inlineStyles.input}
                />
                <div>
                  {error.passwordReq && (
                    <div
                      className={styles['password-error']}
                      style={mergeStyles([inlineStyles.errorText, globalStyles.marginTop5])}
                    >
                      {ErrorMessages.PASSWORD_REQUIREMENTS}
                    </div>
                  )}
                </div>
                {/* </View> */}
              </div>

              {/* PASSWORD REPEAT */}
              <div
                className={`${styles['password-repeat-container']} relative`}
                style={mergeStyles([
                  isMobile || isTablet ? mergeStyles([globalStyles.column, globalStyles.marginTop25]) : {},
                  error.passwordEqual ? globalStyles.marginBottom10 : globalStyles.marginBottom10,
                ])}
              >
                <Label style={[inlineStyles.label]}>Passwort wiederholen</Label>
                {/* <View style={globalStyles.flex1}> */}
                <InputField
                  hasError={error.passwordEqual}
                  onChange={setRepeatedPassword}
                  secure={true}
                  value={repeatedPassword}
                  style={inlineStyles.input}
                />
                <div>
                  {error.passwordEqual && (
                    <div
                      style={mergeStyles([
                        inlineStyles.errorText,
                        // inlineStyles.errorTextPassword2,
                        globalStyles.marginTop5,
                      ])}
                    >
                      {ErrorMessages.PASSWORD_EQUAL}
                    </div>
                  )}
                </div>
                {/* </View> */}
              </div>
            </div>
          </div>

          {/* AGREEMENT CHECKBOXES */}
          <div style={globalStyles.marginVertical25}>
            {/* <View> */}
            <div
              className='relative'
              style={mergeStyles([
                globalStyles.row,
                globalStyles.marginBottom10,
                globalStyles.alignTop,
                globalStyles.marginTop40,
                error.agbs ? globalStyles.marginBottom25 : {},
              ])}
            >
              <Checkbox checked={agbs} label='' onClick={() => setAgbs(!agbs)} />
              <Label style={[globalStyles.marginBottom15]}>
                Ich stimme den{' '}
                <div className={styles.Agreement} onClick={() => openModal(TERM.TERMS)}>
                  allgemeinen Geschäftsbedingungen
                </div>{' '}
                zu.
              </Label>
              {error.agbs && (
                <div className='text' style={mergeStyles([inlineStyles.errorTextCheckbox, globalStyles.marginBottom5])}>
                  {ErrorMessages.REQUIRE_FIELD}
                </div>
              )}
            </div>
            <div
              className='relative'
              style={mergeStyles([
                globalStyles.row,
                globalStyles.marginBottom10,
                globalStyles.alignTop,
                error.dataProtection ? globalStyles.marginBottom25 : {},
              ])}
            >
              <Checkbox checked={dataProtection} label='' onClick={() => setDataProtection(!dataProtection)} />
              <Label style={[globalStyles.marginBottom15]}>
                Ich stimme den{' '}
                <div className={styles.Agreement} onClick={() => openModal(TERM.PRIVACY_POLICY)}>
                  Angaben zum Datenschutz
                </div>{' '}
                zu.
              </Label>
              {error.dataProtection && (
                <div className='text' style={mergeStyles([inlineStyles.errorTextCheckbox, globalStyles.marginBottom5])}>
                  {ErrorMessages.REQUIRE_FIELD}
                </div>
              )}
            </div>
            <div
              className='relative'
              style={mergeStyles([globalStyles.row, globalStyles.marginBottom10, globalStyles.alignTop])}
            >
              <Checkbox checked={newsletter} label='' onClick={() => setNewsletter(!newsletter)} />
              <Label style={[globalStyles.marginBottom15]}>
                Ich möchte über Neuigkeiten per Newsletter informiert werden.
              </Label>
            </div>
            {/* </View> */}
          </div>

          {/* SAVE */}
          <div
            className={`${styles.row} ${styles['save-button-container']}`}
            style={mergeStyles([
              inlineStyles.row,
              isDesktop ? inlineStyles.buttonWebContainer : inlineStyles.buttonContainer,
              isPhablet ? inlineStyles.buttonWebContainer : {},
              isMobileOrTablet ? globalStyles.marginTop30 : {},
            ])}
          >
            <Button
              style={[isSaving ? inlineStyles.buttonLoading : inlineStyles.button, globalStyles.saveButtonContainer]}
              onClick={save}
              disabled={isSaving}
            >
              {isSaving ? <InputLoader /> : null}
              <div className='text' style={mergeStyles([inlineStyles.buttonText])}>
                Jetzt kostenfreie Anfrage stellen
              </div>
            </Button>
            {Object.values(error).some((value) => value === true) && (
              <div className='text' style={mergeStyles([inlineStyles.errorText, inlineStyles.errorTextBottom25])}>
                {ErrorMessages.ERROR}
              </div>
            )}
          </div>

          {/* BOTTOM TEXT NOTICE */}
          <div style={mergeStyles([{ width: '100%', height: 150 }, globalStyles.marginBottom20])}>
            <div
              className='text'
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: 10,
                color: '#B8B8B8',
              }}
            >
              * Eine Fernbehandlung ist nicht in jedem Fall möglich. Voraussetzungen sind die Einhaltung anerkannter
              fachlicher Standards im Sinne des § 630a BGB (unter Einbezug der naturwissenschaftlichen Erkenntnisse, der
              ärztlichen Erfahrung, die zu der Erreichung des ärztlichen Behandlungsziels erforderlich ist und sich in
              der Erprobung bewährt hat) sowie die Feststellung, dass abhängig vom Krankheitsbild kein persönlicher
              Kontakt zwischen Arzt und Patient erforderlich ist. Die Therapieentscheidung wird durch kooperierende
              Ärzte getroffen.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
