import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isInObject } from '../../../helper/UtilityFunctions';
import PharmacyService from '../../../services/Pharmacy.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectPharmacies,
  selectPrescriptionInfo,
  selectUserInformation,
  setPrescriptionInfo,
  setUserInformation,
} from '../../../store/reducers';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Pharmacy } from '../../../types/ninox.types';
import { Headline, Modal, Text, View } from '../../atoms';
import classes from './PharmacyConfirmation.module.scss';

type PharmacyConfirmationModalProps = {
  pharmacy: Pharmacy;
  confirmationModal: boolean;
  setConfirmationModal: (value: boolean) => void;
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export const PharmacyConfirmationModal = ({
  pharmacy,
  confirmationModal,
  setConfirmationModal,
  setCurrentPage,
}: PharmacyConfirmationModalProps) => {
  const navigate = useNavigate();
  const userInformation = useAppSelector(selectUserInformation);
  const prescriptionInfo = useAppSelector(selectPrescriptionInfo);
  const pharmaciesList = useAppSelector(selectPharmacies);
  const creationMode = pharmacy.id === undefined;
  const dispatch = useAppDispatch();

  const patientComesFromFUPRequest = () => {
    return isInObject(prescriptionInfo, 'isSamePharmacy') && prescriptionInfo.isSamePharmacy === false;
  };

  const returnToFUPRequest = (newPhramacyIdOrName: number | string | undefined) => {
    if (!newPhramacyIdOrName) {
      return;
    }

    const reducerType = {
      type: '[UPDATE]',
      payload: {
        followUpPrescriptionsIndex: 7,
      },
    };

    let newPharmacyName;
    if (typeof newPhramacyIdOrName === 'number') {
      const chosenPharmacy = pharmaciesList.find((item) => item.id === newPhramacyIdOrName);

      newPharmacyName = chosenPharmacy?.name;
    }
    if (typeof newPhramacyIdOrName === 'string') {
      newPharmacyName = newPhramacyIdOrName;
    }

    dispatch(
      setPrescriptionInfo({
        ...prescriptionInfo,
        newPharmacy: newPharmacyName,
      })
    );
    setCurrentPage(reducerType);
    navigate('/follow-up-prescription');
  };

  const chosePharmacy = async () => {
    const data = {
      pharmacyId: pharmacy.id,
      patientId: parseInt(userInformation.id, 10),
    };
    const response = await PharmacyService.savePatientPharmacyRelation(data);

    if (!response.error) {
      const newUserInformation = { ...userInformation };
      newUserInformation.patientPharmacyRelation = pharmacy;

      dispatch(setUserInformation(newUserInformation));
    }

    setConfirmationModal(false);
    if (patientComesFromFUPRequest()) {
      returnToFUPRequest(data.pharmacyId);
    } else {
      navigate('/my-pharmacy');
    }
  };

  const createPharmacy = async () => {
    const data = {
      patientId: parseInt(userInformation.id, 10),
      name: pharmacy.name,
      street: pharmacy.street,
      postcode: pharmacy.postcode,
      city: pharmacy.city,
      phone: pharmacy.phone,
      houseNumber: pharmacy.houseNumber,
    };

    const response = await PharmacyService.createPharmacy(data);

    if (!response.error) {
      // eslint-disable-next-line no-param-reassign
      pharmacy.id = response.data.data.id;
      await chosePharmacy();
      if (patientComesFromFUPRequest()) {
        returnToFUPRequest(pharmacy.name);
      } else {
        navigate('/my-pharmacy');
      }
    }
    setConfirmationModal(false);
  };

  return (
    <Modal
      className={classes.PharmacyConfirmationModal}
      modalVisible={confirmationModal}
      setModalVisible={setConfirmationModal}
      primaryButtonText='Diese Apotheke wählen'
      secondaryButtonText='Abbrechen'
      cb={creationMode ? createPharmacy : chosePharmacy}
    >
      <Headline level={3}>Angaben prüfen</Headline>
      <Text className={classes.question}>Möchten Sie diese Apotheke wählen?</Text>
      <View>
        <Text className={classes['pharmacy-name']}>{pharmacy.name}</Text>
        <Text className={classes['pharmacy-address']}>
          {(pharmacy.street ? `${pharmacy.street} ` : '') + (pharmacy.houseNumber ?? '')}
        </Text>
        <Text className={classes['pharmacy-address']}>
          {(pharmacy.postcode ? `${pharmacy.postcode} ` : '') + (pharmacy.city ?? '')}
        </Text>
      </View>
    </Modal>
  );
};
