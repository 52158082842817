import React from 'react';
import classNames from 'classnames/bind';
import type { Medication } from '../../../../types/ninox.types';
import { Icon } from '../../../atoms';
import style from './StageElements.module.scss';

type MedicationProps = {
  medication: Medication[];
};

const cx = classNames.bind(style);

const medicationContainerClasses = cx({ MedicationContainer: true });
const medicationPharmacyIconClasses = cx({ MedicationPharmacyIcon: true });
const titleClasses = cx({ DescriptionTitle: true });
const preparationTitleClasses = cx({ PreparationName: true });
const preparationDetailsCalsses = cx({ PreparationDetails: true });

export const MedicationInfo = (props: MedicationProps) => {
  const { medication } = props;
  const currentMedication = medication[medication.length - 1];

  return (
    <div className={medicationContainerClasses}>
      <div className={medicationPharmacyIconClasses}>
        <Icon icon='medication' size={45} color={'#3db384'} />
      </div>
      <div className={titleClasses}>Meine Medikation</div>
      {currentMedication.preparationListItem.map((item, i) => {
        return (
          <div key={i}>
            <div className={preparationTitleClasses}>
              {item.name} ({item.cultivar})
            </div>
            <div className={preparationDetailsCalsses}>
              Gesamtmenge: {item.quantity} {item.dosageUnit}
              <br />
              Tagesdosis: {item.dosageMax} {item.dosageUnit} | Einmaldosis: {item.dosageSingle} {item.dosageUnit}
            </div>
          </div>
        );
      })}
    </div>
  );
};
