import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, InputField, Label, RadioButton } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { Header } from '../../../organisms';
import style from './Step5.module.scss';

const cx = classNames.bind(style);
export function Step5(): JSX.Element {
  const navigation = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [hasOtherDisease, setHasOtherDisease] = useState<undefined | boolean>();
  const [otherDisease, setOtherDisease] = useState('');

  useEffect(() => {
    const step4 = sessionStorage.getItem('questionnaire-step4');
    if (!step4) {
      navigation('/questionnaire/step-4');
    }
    const step5 = sessionStorage.getItem('questionnaire-step5');
    if (step5 !== null) {
      const parsedData = JSON.parse(step5);
      setHasOtherDisease(parsedData.hasOtherDisease);
      setOtherDisease(parsedData.otherDisease);
    }
    setLoading(false);
  }, []);

  const isButtonDisabled = hasOtherDisease === undefined || (hasOtherDisease && otherDisease === '');

  const nextStep = () => {
    const stepData = {
      hasOtherDisease,
      otherDisease: hasOtherDisease ? otherDisease : '',
    };
    sessionStorage.setItem('questionnaire-step5', JSON.stringify(stepData));
    navigation('/questionnaire/step-6');
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const questionLabelClasses = cx('mb-1');
  const radioContainerClasses = cx({ RadioContainer: true }, 'flex', 'mb-1');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <Header barTitle='Daten vervollständigen' showBackButton={true} />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={5} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Weitere Krankheiten / Diagnosen</Headline>
        </div>

        {!loading && (
          <>
            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Haben Sie noch andere Krankheiten/Diagnosen neben der bereits genannten oder zurückliegende
                  Vorerkrankungen?
                </Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasOtherDiseaseTrue'}
                    name={'hasOtherDisease'}
                    value={'true'}
                    defaultChecked={hasOtherDisease === true}
                    onChange={($event) => setHasOtherDisease($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasOtherDiseaseFalse'}
                    name={'hasOtherDisease'}
                    value={'false'}
                    defaultChecked={hasOtherDisease === false}
                    onChange={($event) => setHasOtherDisease($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
              {hasOtherDisease && (
                <div>
                  <InputField
                    type={'textarea'}
                    placeholder={'Bitte nennen Sie hier alle Krankheiten / Diagnosen und Vorerkrankungen'}
                    onChange={setOtherDisease}
                    value={otherDisease}
                  />
                </div>
              )}
            </div>

            <div className={buttonContainerClasses}>
              <Button onClick={nextStep} disabled={isButtonDisabled}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
