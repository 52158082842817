import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Headline, Icon } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import styles from './AppointmentChoice.module.scss';

export function AppointmentChoice() {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToOfflineFirstAppointments = () => {
    navigate('/profile/offline-first-appointment');
  };

  const navigateToOnlineFirstAppointments = () => {
    navigate('/profile/online-first-appointment');
  };

  const navigateToOfflineAppointments = () => {
    navigate('/profile/offline-appointment');
  };

  const navigateToOnlineAppointments = () => {
    navigate('/profile/online-appointment');
  };

  // having no state means that the user wants to book an initial consultation
  if (!location.state) {
    return (
      <React.Fragment>
        <Header />
        <div className={styles.AppointmentChoice}>
          <Icon icon='calendarWithDays' size={80} color={'#3db384'} />
          <Headline level={3}>Bitte wählen Sie Ihre Präferenz für die Wahrnehmung Ihres Arztgesprächs</Headline>
          <div className={styles.InfoText}>
            Ich möchte meinen Termin vor Ort im Therapiecentrum Düsseldorf wahrhnehmen.
          </div>
          <div className={styles.ButtonContainer}>
            <Button onClick={navigateToOfflineFirstAppointments}>Therapiezentrum wählen</Button>
          </div>
          <div className={styles.InfoText}>
            Wenn in meinem Fall die Möglichkeit besteht, den Arzttermin im Rahmen einer Fernbehandlung wahrzunehmen,
            möchte ich einen Termin zur Fernbehandlung buchen.
          </div>{' '}
          <div className={styles.ButtonContainer}>
            <Button onClick={navigateToOnlineFirstAppointments}>Online-Termin buchen</Button>
          </div>
          <div className={styles.InfoText}>
            Bitte beachten Sie, dass der behandelnde Arzt in jedem Einzelfall selbst prüft und entscheidet, ob eine
            Fernbehandlung möglich ist. Eine Fernbehandlung kann nicht in jedem Fall durchgeführt werden, insbesondere
            dann nicht, wenn ein persönlicher Kontakt zwischen Arzt und Patient erforderlich ist, dies ärztlich nicht
            vertretbar ist und/oder die erforderliche ärztliche Sorgfalt nicht gewahrt wird.
          </div>
        </div>
      </React.Fragment>
    );
  }

  // from here on, we know that the user already had a first consultation
  // more info will be available in 'firstAppointment' (e.g. the practice and the doctor)
  const { firstAppointment } = location.state;

  const { doctorName } = firstAppointment;

  return (
    <React.Fragment>
      <Header />
      <div className={styles.AppointmentChoice}>
        <Icon icon='calendarWithDays' size={80} color={'#3db384'} />
        <Headline level={3}>Bitte wählen Sie die Art Ihres Folgetermins</Headline>
        <div className={styles.InfoText}>
          Ich möchte meinen Termin vor Ort im Therapiecentrum Düsseldorf wahrhnehmen.
        </div>
        <div className={styles.ButtonContainer}>
          <Button onClick={navigateToOfflineAppointments}>Termin vor Ort buchen</Button>
        </div>
        <div className={styles.InfoText}>
          Ich möchte einen <b>Online-Termin</b> bei meinem behandelnden Arzt / meiner behandelndin Ärzting {doctorName}{' '}
          buchen.
        </div>
        <div className={styles.ButtonContainer}>
          <Button onClick={navigateToOnlineAppointments}>Online-Termin buchen</Button>
        </div>
      </div>
    </React.Fragment>
  );
}
