import React from 'react';
import { Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

export const PatientDeclined = () => {
  return (
    <>
      <div className={classes.CircleDeclined}>
        <Icon icon='cross' size={80} color={'white'} />
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitleOrange}>Ihre Therapie ist unterbrochen</div>
        <div className={classes.DescriptionText}>
          Eine Fortsetzung Ihrer Therapie mit nowomed ist derzeit nicht möglich. Falls Sie weitere Fragen haben, wenden
          Sie sich bitte an unseren Support.
        </div>
      </div>
    </>
  );
};
