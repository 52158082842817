import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

export const BookFirstAppointment = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <Icon icon='calendarWithDays' size={70} color={'white'} />
        <div className={classes.CircleText}>
          Erstgespräch
          <br />
          buchen
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>Erstgespräch buchen</div>
        <div className={classes.DescriptionText}>
          Buchen Sie jetzt Ihren Termin für Ihr Erstgespräch. Dieses kann in einem unserer Therapiezentren oder online
          stattfinden.
        </div>
        <div className={classes.ActionButtons}>
          <Button onClick={() => navigate('/profile/appointment-choice')}>Jetzt Termin buchen</Button>
        </div>
      </div>
    </>
  );
};
