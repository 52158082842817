import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
  status?: number;
};

// eslint-disable-next-line import/no-default-export
export default class PharmacyService {
  static async getPharmacies(filterParam?: string): Promise<Response> {
    let filter = filterParam;
    const token = localStorage.getItem('JWT');
    if (filter === undefined) {
      filter = '';
    }

    return new Promise((resolve) => {
      axios
        .get(`/pharmacy/filter/${filter}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async savePatientPharmacyRelation(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/pharmacy/relation', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async createPharmacy(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/pharmacy', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
