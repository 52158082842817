import React from 'react';
import { Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

type StayTunedProps = {
  filteredPatientAppointments: any;
};

export const StayTuned = (props: StayTunedProps) => {
  const { filteredPatientAppointments } = props;
  const latestAppointment =
    filteredPatientAppointments?.length > 0
      ? filteredPatientAppointments[filteredPatientAppointments.length - 1]
      : null;

  return (
    <>
      <div className={classes.CircleWithIconSmall}>
        <Icon icon='waiting' size={80} color={'white'} />
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>Ihr Termin hat stattgefunden</div>
        <div className={classes.DescriptionText}>
          Sie hatten kürzlich Ihren Termin bei {latestAppointment.doctorName}.
        </div>
        <div className={classes.DescriptionText}>
          Bitte gedulden Sie sich etwas, bis Ihr Arzt / Ihre Ärztin die Nachbereitung abgeschlossen hat.
        </div>
      </div>
    </>
  );
};
