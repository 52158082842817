import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '../../../atoms';
import classes from './StageElements.module.scss';

export const WaitingForApproval = () => {
  return (
    <>
      <div className={classes.CircleWithIconSmall}>
        <Icon icon='waiting' size={80} color={'white'} />
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>Wir prüfen Ihre Anfrage</div>
        <div className={classes.DescriptionText}>
          Momentan prüft unser medizinisches Team Ihre Therapieanfrage. Dies dauert in der Regel nicht länger als 48
          Stunden. Anschließend können Sie Ihr persönliches Erstgespräch buchen.
        </div>
        <div className={classes.DescriptionText}>
          Weitere Dokumente können den Prüfungsprozess beschleunigen.
          <Link className={classes.UploadDocuments} to='/profile/documents?page=my-documents'>
            Jetzt Dokumente hochladen
          </Link>
        </div>
      </div>
    </>
  );
};
