import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { Address } from '../types/ninox.types';
import type { DoctorSlot } from '../types/user.types';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
  status?: number;
};

// eslint-disable-next-line import/no-default-export
export default class UserService {
  static async register(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/register', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async checkEmail(data: any): Promise<Response> {
    return new Promise<Response>((resolve) => {
      axios
        .post('/user/is-mail-exist', data, {
          validateStatus: (status) => {
            return status === 200 || status === 409;
          },
        })
        .then((response: AxiosResponse<any>) => {
          resolve({
            error: false,
            status: response.status,
            message: response.data.message,
          });
        })
        .catch((error: Response) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async login(data: any): Promise<Response> {
    const stayLoggedIn = false;

    return new Promise((resolve) => {
      axios
        .post('/user/authenticate', { stayLoggedIn, ...data })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async forgotPassword(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/forgot-password', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async resetPassword(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/reset-password', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async deleteAccount(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .delete('/user/delete', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async checkTrackingToken(token: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/check-token', token)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async saveTrackingData(data: any): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/save-tracking-data', data)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDataForTracking(token: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .post('/user/get-data-for-tracking', token)
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async createPatientAddress(data: Address): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/user/createPatientAddress', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async update(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put('/user/update', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async updateUserInfo(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put('/user/update-user-info', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async deleteFile(id: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .delete(`/user/file/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async information(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/user/information', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getFiles(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/user/file', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getFile(id: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/document/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getProgress(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/user/progress', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctors(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/user/doctors', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorSlots(doctorId: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/doctor/${doctorId}/free-appointments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorOnlineSlotsBySlotType(doctorId: number, slotType: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/doctor/${doctorId}/${slotType}/free-online-appointments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorSlotsBySlotType(doctorId: number, slotType: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/doctor/${doctorId}/${slotType}/free-appointments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getMultipleDoctorOnlineSlotsBySlotType(doctorIds: number[], slotType: number): Promise<any> {
    const doctorPromises: any = [];
    const doctorSlots: DoctorSlot[] = [];

    new Set(doctorIds).forEach((id) => {
      doctorPromises.push(
        // eslint-disable-next-line consistent-return
        UserService.getDoctorOnlineSlotsBySlotType(id, slotType).then((res) => {
          if (res.error) {
            return {
              error: true,
              code: 500,
              message: 'Error on get doctor slots',
            };
          }
          doctorSlots.push(...res.data);
        })
      );
    });

    await Promise.all(doctorPromises);

    return { error: false, data: doctorSlots };
  }

  static async getMultipleDoctorSlotsBySlotType(doctorIds: number[], slotType: number): Promise<any> {
    const doctorPromises: any = [];
    const doctorSlots: DoctorSlot[] = [];

    new Set(doctorIds).forEach((id) => {
      doctorPromises.push(
        // eslint-disable-next-line consistent-return
        UserService.getDoctorSlotsBySlotType(id, slotType).then((res) => {
          if (res.error) {
            return {
              error: true,
              code: 500,
              message: 'Error on get doctor slots',
            };
          }
          doctorSlots.push(...res.data);
        })
      );
    });

    await Promise.all(doctorPromises);

    return { error: false, data: doctorSlots };
  }

  static async uploadFile(file: FormData): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/user/file', file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async confirmData(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put(
          '/user/confirmData',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async assignDoctorWithType(userId: number, doctorId: number, type: string): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post(
          `/patient/assign-doctor/${userId}/${doctorId}/${type}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async assignDoctor(userId: number): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post(
          `/patient/${userId}/doctor/assign-random`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async confirmTerms(terms: { terms: boolean; privacyPolicy: boolean; newsletter: boolean }): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/user/confirmTerms', terms, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async confirmAgbs(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put(
          '/user/confirmAgbs',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async confirmPrivacyPolicy(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put(
          '/user/confirmPrivacyPolicy',
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async activateAccount(token: string, email: string): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .put('/user/activate', {
          token,
          email,
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async sendDoctorCertificate(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/document/doctor-certificate', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async followPrescription(data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .post('/followup-prescription-question', data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async availableFollowupPrescription(patientId: number, data: any): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .put(`/patient/${patientId}/available-followup-prescription`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getLocations(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/practice/locations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAllDoctors(): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/doctor', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorAbsence(doctorId: number | undefined): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/doctor/${doctorId}/absence`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctorAbsenceCurrentMonth(doctorId: number | undefined): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/doctor/${doctorId}/absence-current-month`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getDoctor(doctorId: number | undefined): Promise<Response> {
    const token = localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get(`/doctor/${doctorId}/details`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
