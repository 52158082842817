import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserInformation } from '../../../../store/reducers';
import type { Pharmacy } from '../../../../types/ninox.types';
import { Button, Image, Text, View } from '../../../atoms';
import { PremiumCheck } from '../../../atoms/Icon/Icons/PremiumCheck';
import classes from './PremiumPharmacy.module.scss';

type PremiumPharmacyProps = {
  pharmacy: Pharmacy;
  setConfirmationModal: (value: boolean) => void;
  setChosenPharmacy: (pharmacy: Pharmacy) => void;
};

export const PremiumPharmacy = ({
  pharmacy,
  setConfirmationModal,
  setChosenPharmacy,
}: PremiumPharmacyProps): JSX.Element => {
  const navigate = useNavigate();

  const userInformation = useAppSelector(selectUserInformation);
  const [showDetails, setShowDetails] = useState(false);
  const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;

  const Details = () => (
    <View className={classes.Details} key={pharmacy.id}>
      <Text className={classes.description} children={pharmacy.descriptionShort} />
      <View className={classes.divider}>
        <View className={`row justify-center ${classes['button-row']}`}>
          <Button
            className={`${classes.button} ${classes['button-details']}`}
            onClick={() => {
              navigate(`/pharmacy-details/${pharmacy.id}`);
            }}
            disabled={false}
          >
            <Text className={classes['button-text']}>Details</Text>
          </Button>
          <Button
            className={`${classes.button} ${classes['button-choose-pharmacy']}`}
            onClick={() => {
              setConfirmationModal(true);
              setChosenPharmacy(pharmacy);
            }}
            disabled={disable}
          >
            <Text className={classes['button-text']}>{disable ? 'Ausgewählte Apotheke' : 'Apotheke wählen'}</Text>
          </Button>
        </View>
      </View>
    </View>
  );

  return (
    <View className={classes.PremiumPharmacy}>
      <div className='clickable' onClick={() => setShowDetails(!showDetails)}>
        <Image
          className={classes.image}
          tintColor={'#fff'}
          source={{
            height: 60,
            width: 250,
            uri: `data:image/jpg;base64,${pharmacy.image}`,
          }}
        />
      </div>
      {showDetails ? <Details /> : <View className={classes['details-line']} />}
      <View className={classes['rating-row']}>
        <View className='absolute left'>
          <Text className={classes['premium-check']}>
            <PremiumCheck color={'#e3e3e3'} style={{ marginRight: 10 }} />
            Beliebte Apotheke
          </Text>
        </View>
        <View className='absolute right'>
          <StarRatings
            rating={pharmacy.rating}
            starDimension='19px'
            starEmptyColor={'rgba(255, 255, 255, 0.25)'}
            starRatedColor={'#fff'}
            starSpacing='2px'
          />
        </View>
      </View>
    </View>
  );
};
