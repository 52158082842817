import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DOCTOR_SLOT } from '../../../../enums/doctorslot.enum';
import UserService from '../../../../services/User.service';
import { useAppDispatch } from '../../../../store/hooks';
import { setAuth } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import type { DoctorSlot } from '../../../../types/user.types';
import { Button, Icon, Loading } from '../../../atoms';
import { Calendar } from '../../../organisms/Calendar/Calendar';
import style from './StepOneOnlineFirstAppointment.module.scss';

const cx = classNames.bind(style);

type StepOneOnlineFirstAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  onlineFirstAppointmentInfo: any;
  setOnlineFirstAppointmentInfo: (value: any) => void;
};
const stepsOnlineAppointmentClasses = cx({ StepOneOnlineFirstAppointment: true, StepsContainer: true });
const infoTextClasses = cx({ InfoText: true });
const calendarContainerClasses = cx({ CalendarContainer: true });
const buttonConatinerClasses = cx({ ButtonContainer: true });

export function StepOneOnlineFirstAppointment({
  setCurrentPage,
  onlineFirstAppointmentInfo,
  setOnlineFirstAppointmentInfo,
}: StepOneOnlineFirstAppointmentProps) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [doctorSlots, setDoctorSlots] = useState<DoctorSlot[]>([]);
  const [allDoctorSlots, setAllDoctorSlots] = useState<DoctorSlot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<DoctorSlot>();
  const [doctors, setDoctors] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  const getDoctorSlots = () => {
    setLoading(true);
    const ids: number[] = [];
    UserService.getAllDoctors().then((res) => {
      if (res.error) {
        console.error(res.message);
        setLoading(false);

        return;
      }
      res.data.map((item: any) => ids.push(item.id));
      setDoctors(res.data);

      UserService.getMultipleDoctorOnlineSlotsBySlotType(ids, DOCTOR_SLOT.FIRST_APPOINTMENT).then((res2) => {
        if (res2.error) {
          if (res2.message.response.status === 401) {
            dispatch(setAuth({ isAuthenticated: false }));
            setLoading(false);
            navigate('/login');
          }
          console.error(res2.message);
        }
        if (!res2.data.length) {
          setLoading(false);
          navigate('/unavailable-appointment');

          return;
        }
        setAllDoctorSlots(res2.data);
      });
      setLoading(false);
    });
  };

  const doctorSlotsFilter = () => {
    setDoctorSlots(allDoctorSlots);
  };

  useEffect(() => {
    getDoctorSlots();
    if (onlineFirstAppointmentInfo.selectedDate !== undefined) {
      setSelectedDate(onlineFirstAppointmentInfo.selectedDate);
    }
  }, []);

  useEffect(() => {
    const slots = doctorSlots.filter(
      (slot: DoctorSlot) =>
        new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() === new Date(selectedDate).getTime()
    );
    setSelectedSlot(slots[0]);
  }, [selectedDate, doctorSlots]);

  const next = () => {
    if (selectedSlot) {
      setOnlineFirstAppointmentInfo({
        ...onlineFirstAppointmentInfo,
        selectedDate,
        doctors,
        doctorSlots: doctorSlots.filter(
          (slot: DoctorSlot) =>
            new Date(new Date(slot.start).setHours(0, 0, 0, 0)).getTime() - selectedDate.getTime() === 0
        ),
      });
      const reducerType = {
        type: '[UPDATE]',
        payload: {
          onlineFirstAppointmentIndex: 2,
        },
      };
      setCurrentPage(reducerType);
    }
  };

  useEffect(() => {
    doctorSlotsFilter();
  }, [allDoctorSlots]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={stepsOnlineAppointmentClasses}>
      <Icon icon='calendarWithDays' size={80} color={'#3db384'} />
      <div className={infoTextClasses}>Wann soll Ihr Online Erstgespräch stattfinden?</div>
      {doctorSlots?.length !== 0 ? (
        <div className={calendarContainerClasses}>
          <Calendar slots={doctorSlots} onChange={setSelectedDate} selectedDate={selectedDate} />
        </div>
      ) : (
        <Loading />
      )}
      {doctorSlots?.length !== 0 && (
        <div className={buttonConatinerClasses}>
          <Button disabled={!selectedDate || doctorSlots.length === 0} onClick={next}>
            Weiter
          </Button>
        </div>
      )}
    </div>
  );
}
