import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};

type Response = {
  error: boolean;
  message?: any;
  data?: any;
  status?: number;
};

// eslint-disable-next-line import/no-default-export
export default class TermsService {
  static async getActiveTerms(): Promise<Response> {
    return new Promise((resolve) => {
      axios
        .get('/terms/active')
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }

  static async getAgreedTerms(): Promise<Response> {
    const token = await localStorage.getItem('JWT');

    return new Promise((resolve) => {
      axios
        .get('/terms/agreed', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          resolve({ error: false, data: response.data });
        })
        .catch((error) => {
          resolve({ error: true, message: error });
        });
    });
  }
}
