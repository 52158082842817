import type { CSSProperties } from 'react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import UserService from '../../../services/User.service';
import { useAppDispatch } from '../../../store/hooks';
import { setAuth, setUserInformation } from '../../../store/reducers';
import { Button, InputField, Label } from '../../atoms';
import { Checkbox } from '../../molecules/Checkbox/Checkbox';
import { BaseHeader } from '../../organisms/BaseHeader/BaseHeader';
import { styles as inlineStyles } from './Login.style';
import styles from './Login.module.scss';

export function Login() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [error, setError] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  // Activate User's Account
  useEffect(() => {
    const token = searchParams.get('token');
    const paramEmail = searchParams.get('email');

    if (token && paramEmail) {
      UserService.activateAccount(token, paramEmail).then((res) => {
        if (res.error) {
          console.error(res);
        }
      });
    }
  }, []);

  const login = async () => {
    setError('');
    const loginData = { email, password, stayLoggedIn };

    const response = await UserService.login(loginData);
    if (!response.error) {
      localStorage.setItem('JWT', response.data.token);
      localStorage.setItem('User', response.data.name);
      localStorage.setItem('TermsAgree', String(response.data.isTermsAgree));
      localStorage.setItem('PrivacyPolicyAgree', String(response.data.isPrivacyPolicyAgree));
      localStorage.setItem('NewsletterAgree', String(response.data.isNewsletterAgree));
      localStorage.setItem('FirstTime', 'false');
      setEmail('');
      setPassword('');
      const authData = {
        isAuthenticated: true,
        isTermsAgree: response.data.isTermsAgree,
        name: response.data.name,
        isPrivacyPolicyAgree: response.data.isPrivacyPolicyAgree,
        isNewsletterAgree: response.data.isNewsletterAgree,
      };
      dispatch(setAuth(authData));

      UserService.information().then((r) => {
        if (r.error) {
          console.error(r.message);
        }

        dispatch(setUserInformation(r.data));
        if (authData.isTermsAgree && authData.isPrivacyPolicyAgree) {
          navigate('/dashboard');
        } else {
          navigate('/confirm-terms');
        }
      });
    }

    if (response.error) {
      if (response.message.response?.data?.error) {
        setError(response.message.response.data.error);
      } else {
        setError('error');
      }
      console.error(response.message);
    }
  };

  const errorStyle = inlineStyles.error as CSSProperties;
  const passwordStyles = mergeStyles([
    inlineStyles.inputContainer as CSSProperties,
    inlineStyles.marginBottomXL as CSSProperties,
  ]);

  return (
    <React.Fragment>
      <BaseHeader />
      <div>
        <div className={styles.container} style={inlineStyles.container}>
          <div style={inlineStyles.heading3}>{searchParams?.get('token') ? 'Verifizierung erfolgreich!' : 'Login'}</div>
          <Label className={styles['first-label']} style={[{ marginBottom: 20 }]}>
            Melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.
          </Label>

          <div className={styles['input-container']} style={inlineStyles.inputContainer as CSSProperties}>
            <div>
              <Label>E-Mail-Adresse</Label>
            </div>
            <div className={styles['input-field']}>
              <InputField
                onChange={setEmail}
                value={email}
                style={[inlineStyles.input]}
                isEmail={true}
                onKeyPress={(event) => (event.nativeEvent.key === 'Enter' ? login() : null)}
              />
            </div>
            {error === 'email' && <div style={errorStyle}>Die E-Mail-Adresse ist falsch.</div>}
            {error === 'error' && (
              <div style={errorStyle}>Es ist ein Fehler aufgetreten, versuchen Sie es bitte erneut.</div>
            )}
          </div>

          <div style={passwordStyles}>
            <div>
              <Label>Passwort</Label>
            </div>
            <div className={styles['input-field']}>
              <InputField
                onChange={setPassword}
                secure={true}
                value={password}
                style={inlineStyles.input}
                onKeyPress={(event) => (event.key === 'Enter' ? login() : null)}
              />
            </div>
            {error === 'password' && (
              <div style={inlineStyles.error as CSSProperties}>Das Passwort ist nicht korrekt.</div>
            )}
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ minWidth: '11rem' }}>
              <Checkbox
                checked={stayLoggedIn}
                label='Eingeloggt bleiben'
                onClick={() => setStayLoggedIn(!stayLoggedIn)}
              />
            </div>
          </div>

          <div>
            {error === 'delete' && (
              <div className={styles['error-delete']} style={inlineStyles.errorDelete as CSSProperties}>
                Ihr Account befindet sich auf Ihren Wunsch in einem Löschungsvorgang. Sie können sich nicht mehr
                anmelden.
              </div>
            )}
          </div>

          <div>
            {error === 'pharmacyUser' && (
              <div className={styles['error-delete']} style={inlineStyles.errorDelete as CSSProperties}>
                Dieses Angebot richtet sich an die Patienten von nowomed. Fahren Sie über “Jetzt registrieren” fort, um
                eine kostenlose Therapieanfrage zu stellen.
              </div>
            )}
          </div>

          <div className={styles['button-container']} style={inlineStyles.buttonContainer as CSSProperties}>
            {error === 'active' && (
              <div className={styles['error-unverified']} style={inlineStyles.errorUnverified as CSSProperties}>
                Ihr Account ist nicht verifiziert. Bitte verifizieren Sie Ihren Account über den Link in der Ihnen
                gesendeten E-Mail.
              </div>
            )}
            <Button onClick={login}>Anmelden</Button>
            <Link style={inlineStyles.marginTop20} to='/forgot-password'>
              <div style={inlineStyles.backButtonText as CSSProperties}>Passwort vergessen?</div>
            </Link>
            {!searchParams?.get('token') && (
              <React.Fragment>
                <Label
                  style={[
                    inlineStyles.marginTop50,
                    inlineStyles.marginBottom20,
                    inlineStyles.alignCenter as CSSProperties,
                  ]}
                >
                  Sie haben noch kein Konto?
                </Label>
                <Link to='/register'>
                  <div style={inlineStyles.backButtonText}>Jetzt kostenfrei registrieren</div>
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
