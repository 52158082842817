import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import { Header } from '../../organisms/Header/Header';
import { StepFourOfflineFirstAppointment } from './OfflineFirstAppointmentSteps/StepFourOfflineFirstAppointment';
import { StepOneOfflineFirstAppointment } from './OfflineFirstAppointmentSteps/StepOneOfflineFirstAppointment';
import { StepThreeOfflineFirstAppointment } from './OfflineFirstAppointmentSteps/StepThreeOfflineFirstAppointment';
import { StepTwoOfflineFirstAppointment } from './OfflineFirstAppointmentSteps/StepTwoOfflineFirstAppointment';
import style from './OfflineFirstAppointment.module.scss';

const cx = classNames.bind(style);

type OfflineFirstAppointmentProps = {
  currentPage: number;
  setCurrentPage: React.Dispatch<ReducerAction>;
};

const offlineFirstAppointmentClasses = cx({ OfflineFirstAppointment: true });

export function OfflineFirstAppointment({ currentPage, setCurrentPage }: OfflineFirstAppointmentProps) {
  const [firstAppointmentInfo, setFirstAppointmentInfo] = useState({});

  useEffect(() => {
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        offlineFirstAppointmentIndex: 1,
      },
    };
    setCurrentPage(reducerType);
  }, []);

  if (Object.entries(firstAppointmentInfo).length === 0 && currentPage !== 1) {
    return null;
  }

  let pageToRender;
  switch (currentPage) {
    case 1:
      pageToRender = (
        <StepOneOfflineFirstAppointment
          setCurrentPage={setCurrentPage}
          firstAppointmentInfo={firstAppointmentInfo}
          setFirstAppointmentInfo={setFirstAppointmentInfo}
        />
      );
      break;
    case 2:
      pageToRender = (
        <StepTwoOfflineFirstAppointment
          setCurrentPage={setCurrentPage}
          firstAppointmentInfo={firstAppointmentInfo}
          setFirstAppointmentInfo={setFirstAppointmentInfo}
        />
      );
      break;
    case 3:
      pageToRender = (
        <StepThreeOfflineFirstAppointment
          setCurrentPage={setCurrentPage}
          firstAppointmentInfo={firstAppointmentInfo}
          setFirstAppointmentInfo={setFirstAppointmentInfo}
        />
      );
      break;
    case 4:
      pageToRender = (
        <StepFourOfflineFirstAppointment setCurrentPage={setCurrentPage} firstAppointmentInfo={firstAppointmentInfo} />
      );
      break;
    default:
      pageToRender = <></>;
  }

  return (
    <React.Fragment>
      <Header showBackButton={true} barTitle='Erstgespräch buchen' />
      <div className={offlineFirstAppointmentClasses}>{pageToRender}</div>
    </React.Fragment>
  );
}
