import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, Icon } from '../../atoms';
import { Header } from '../../organisms/Header/Header';
import classes from './OfflineAppointmentSuccess.module.scss';

const cx = classNames.bind(classes);

export function OfflineAppointmentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();

  const offlineAppointmentClasses = cx({ OfflineAppointment: true });
  const stepsOfflineAppointmentClasses = cx({ OfflineAppointmentSuccess: true, StepsContainer: true });
  const appointmentSuccessContainerClasses = cx({ AppointmentSuccessContainer: true });
  const appointmentSuccessMainClasses = cx({ AppointmentSuccessMain: true });
  const appointmentSuccessInfoClasses = cx({ AppointmentSuccessInfo: true });
  const buttonConatinerClasses = cx({ ButtonContainer: true });

  if (!location.state) {
    return (
      <React.Fragment>
        <Header />
        <Headline level={3}>Diese Seite ist nicht mehr gültig.</Headline>
        <div className={classes['button-container']}>
          <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
        </div>
      </React.Fragment>
    );
  }

  const { appointmentData } = location.state;

  return (
    <React.Fragment>
      <Header showBackButton={true} barTitle='Folgetermin buchen' />
      <div className={offlineAppointmentClasses}>
        <div className={stepsOfflineAppointmentClasses}>
          <Icon icon={'checkCircleFilled'} size={80} color={'#3db384'} />
          <Headline level={3}>Ihr Folgetermin ist gebucht!</Headline>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessMainClasses}>
              Ihr Termin am{' '}
              {appointmentData &&
                new Date(appointmentData.startDate).toLocaleString('de-DE', {
                  month: 'long',
                  day: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}{' '}
              Uhr wurde erfolgreich gebucht. Eine Bestätigung wurde Ihnen soeben per E-Mail übermittelt.
            </div>
          </div>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessInfoClasses}>
              Mit der E-Mail erhalten Sie weitere wichtige Informationen zu Ihrem Termin.
            </div>
          </div>
          <div className={appointmentSuccessContainerClasses}>
            <div className={appointmentSuccessInfoClasses}>
              Auf Ihrem Dashboard werden Ihnen alle weiteren Informationen angezeigt.
            </div>
          </div>
          <div className={buttonConatinerClasses}>
            <Button onClick={() => navigate('/dashboard')}>Zum Dashboard</Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
