import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import UserService from '../../../../services/User.service';
import type { PracticeLocation } from '../../../../types/ninox.types';
import { Button, Headline, Icon, Loading } from '../../../atoms';
import style from './StepOneOfflineFirstAppointment.module.scss';

const cx = classNames.bind(style);
const stepsOfflineAppointmentClasses = cx({ StepOneOfflineFirstAppointment: true, StepsContainer: true });
const locationClasses = cx({ Location: true });
const locationNameClasses = cx({ LocationName: true });
const locationDetailsWithIconClasses = cx({ LocationDetailsWithIcon: true });
const locationChooseButtonClasses = cx({ locationChooseButton: true });
// const infoTextClasses = cx({ InfoText: true });

export function StepOneOfflineFirstAppointment({ setCurrentPage, firstAppointmentInfo, setFirstAppointmentInfo }: any) {
  const [locations, setLocations] = useState<PracticeLocation[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    UserService.getLocations().then((res) => {
      if (res.error) {
        setLoading(false);
      }

      const locationsNew = res.data.map((location: PracticeLocation) => ({
        ...location,
        active: 'false',
      }));

      setLocations(locationsNew);
      setLoading(false);
    });
  }, []);

  const next = (selectedLocation: any) => {
    setFirstAppointmentInfo({
      ...firstAppointmentInfo,
      location: selectedLocation,
    });
    const reducerType = {
      type: '[UPDATE]',
      payload: {
        offlineFirstAppointmentIndex: 2,
      },
    };
    setCurrentPage(reducerType);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={stepsOfflineAppointmentClasses}>
      <Icon icon='marker' size={80} color={'#3db384'} />
      <Headline level={3}>Wo soll Ihr Erstgespräch stattfinden?</Headline>

      <div>
        {locations &&
          locations.map((location: any) => (
            <div key={location.name} className={locationClasses}>
              <div className={locationNameClasses}>{location.name}</div>

              <div className={locationDetailsWithIconClasses}>
                <Icon icon='marker' size={14} color='#3db384' />
                <div>{location.address}</div>
              </div>
              <br />
              {location.openingTimes && (
                <>
                  <div className={locationDetailsWithIconClasses}>
                    <Icon icon='clock' size={14} color='#3db384' />
                    <div>{location.openingTimes}</div>
                  </div>
                  <br />
                </>
              )}
              <div className={locationDetailsWithIconClasses}>
                <Icon icon='user' size={14} color='#3db384' />
                <div>{location.doctors}</div>
              </div>
              <div className={locationChooseButtonClasses}>
                <Button inlineBlock={true} onClick={() => next(location)}>
                  Termine anzeigen
                </Button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
