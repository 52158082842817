import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline, InputField, Label, RadioButton } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { Header } from '../../../organisms';
import style from './Step3.module.scss';

const cx = classNames.bind(style);
export function Step3(): JSX.Element {
  const navigation = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [therapiesMedicationCondition, setTherapiesMedicationCondition] = useState('');
  const [lastVisitDoctor, setLastVisitDoctor] = useState('');
  const [hasSymptomsChanged, setHasSymptomsChanged] = useState<undefined | boolean>();
  const [symptomsChanged, setSymptomsChanged] = useState('');

  useEffect(() => {
    const step2 = sessionStorage.getItem('questionnaire-step2');
    if (!step2) {
      navigation('/questionnaire/step-2');
    }
    const step3 = sessionStorage.getItem('questionnaire-step3');
    if (step3 !== null) {
      const parsedData = JSON.parse(step3);
      setTherapiesMedicationCondition(parsedData.therapiesMedicationCondition);
      setLastVisitDoctor(parsedData.lastVisitDoctor);
      setHasSymptomsChanged(parsedData.hasSymptomsChanged);
      setSymptomsChanged(parsedData.symptomsChanged);
    }
    setLoading(false);
  }, []);

  const isButtonDisabled =
    therapiesMedicationCondition === '' ||
    lastVisitDoctor === '' ||
    hasSymptomsChanged === undefined ||
    (hasSymptomsChanged && symptomsChanged === '');

  const nextStep = () => {
    const stepData = {
      therapiesMedicationCondition,
      lastVisitDoctor,
      hasSymptomsChanged,
      symptomsChanged: hasSymptomsChanged ? symptomsChanged : '',
    };
    sessionStorage.setItem('questionnaire-step3', JSON.stringify(stepData));
    navigation('/questionnaire/step-4');
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-3');
  const questionLabelClasses = cx('mb-1');
  const radioContainerClasses = cx({ RadioContainer: true }, 'flex', 'mb-1');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <Header barTitle='Daten vervollständigen' showBackButton={true} />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={3} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Bisherige Therapien</Headline>
        </div>
        {!loading && (
          <>
            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>
                  Welche Therapien oder Medikamente haben Sie bisher aufgrund dieser Erkrankung ausprobiert?
                </Label>
              </div>
              <div>
                <InputField
                  type={'textarea'}
                  onChange={setTherapiesMedicationCondition}
                  value={therapiesMedicationCondition}
                />
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Wann waren Sie wegen dieser Erkrankung zuletzt bei einem Arzt oder Therapeuten?</Label>
              </div>
              <div>
                <RadioButton
                  id={'lastVisitDoctorLast3months'}
                  name={'lastVisitDoctor'}
                  value={'last3months'}
                  extraClasses={'mb-1'}
                  defaultChecked={lastVisitDoctor === 'last3months'}
                  onChange={($event) => setLastVisitDoctor($event.target.value)}
                >
                  In den letzten 3 Monaten
                </RadioButton>
                <RadioButton
                  id={'lastVisitDoctorLast3to6months'}
                  name={'lastVisitDoctor'}
                  value={'last3to6months'}
                  extraClasses={'mb-1'}
                  defaultChecked={lastVisitDoctor === 'last3to6months'}
                  onChange={($event) => setLastVisitDoctor($event.target.value)}
                >
                  In den letzten 3 bis 6 Monaten
                </RadioButton>
                <RadioButton
                  id={'lastVisitDoctorLast6to12months'}
                  name={'lastVisitDoctor'}
                  value={'last6to12months'}
                  extraClasses={'mb-1'}
                  defaultChecked={lastVisitDoctor === 'last6to12months'}
                  onChange={($event) => setLastVisitDoctor($event.target.value)}
                >
                  In den letzten 6 bis 12 Monaten
                </RadioButton>
                <RadioButton
                  id={'lastVisitDoctorOver1year'}
                  name={'lastVisitDoctor'}
                  value={'over1year'}
                  defaultChecked={lastVisitDoctor === 'over1year'}
                  onChange={($event) => setLastVisitDoctor($event.target.value)}
                >
                  Vor über einem Jahr
                </RadioButton>
              </div>
            </div>

            <div className={questionContainerClasses}>
              <div className={questionLabelClasses}>
                <Label>Haben sich Ihre Beschwerden seitdem grundlegend geändert? </Label>
              </div>
              <div className={radioContainerClasses}>
                <span>
                  <RadioButton
                    id={'hasSymptomsChangeTrue'}
                    name={'hasSymptomsChange'}
                    value={'true'}
                    onChange={($event) => setHasSymptomsChanged($event.target.value === 'true')}
                  >
                    Ja
                  </RadioButton>
                </span>
                <span>
                  <RadioButton
                    id={'hasSymptomsChangeFalse'}
                    name={'hasSymptomsChange'}
                    value={'false'}
                    onChange={($event) => setHasSymptomsChanged($event.target.value === 'true')}
                  >
                    Nein
                  </RadioButton>
                </span>
              </div>
              {hasSymptomsChanged && (
                <div>
                  <InputField
                    type={'textarea'}
                    placeholder={'Inwiefern haben sich Ihre Beschwerden geändert?'}
                    onChange={setSymptomsChanged}
                    value={symptomsChanged}
                  />
                </div>
              )}
            </div>

            <div className={buttonContainerClasses}>
              <Button onClick={nextStep} disabled={isButtonDisabled}>
                Weiter
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
