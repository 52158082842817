import React from 'react';
import { AccordionTile } from '../../molecules';
import classes from './Accordion.module.scss';

type AccordionProps = {
  accordionData: any[];
};

export function Accordion({ accordionData }: AccordionProps) {
  return (
    <div className={classes.Accordion}>
      {accordionData.map(({ title, content }, index) => (
        <AccordionTile key={index} title={title} content={content} />
      ))}
    </div>
  );
}
