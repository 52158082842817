import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { DOCTOR_SLOT_SUBTYPE } from '../../../../enums/doctorslot.enum';
import AppointmentService from '../../../../services/Appointment.service';
import UserService from '../../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { loadUserInformation, selectUserInformation, setAuth } from '../../../../store/reducers';
import type { ReducerAction } from '../../../../types/navigationIndex.types';
import { Button, Icon, InputField, Loading } from '../../../atoms';
import { ContractCheckbox } from '../../../organisms/ContractCheckbox/ContractCheckbox';
import classes from './StepThreeOnlineFirstAppointment.module.scss';

const cx = classNames.bind(classes);

type StepThreeOnlineFirstAppointmentProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
  onlineFirstAppointmentInfo: any;
};

const stepsOnlineAppointmentClasses = cx({ StepThreeOnlineFirstAppointment: true, StepsContainer: true });
const infoTextClasses = cx({ InfoText: true });
const appointmentInfoContainerClasses = cx({ AppointmentInfoContainer: true });
const appointmentInfoLabelClasses = cx({ AppointmentInfoLabel: true });
const couponCodeFieldClasses = cx({ CouponCodeField: true });
const appointmentInfoDescriptionClasses = cx({ AppointmentInfoDescription: true });
const buttonConatinerClasses = cx({ ButtonContainer: true });
const backButtonClasses = cx({ BackButton: true });

export function StepThreeOnlineFirstAppointment({
  setCurrentPage,
  onlineFirstAppointmentInfo,
}: StepThreeOnlineFirstAppointmentProps) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const userInformation = useAppSelector(selectUserInformation);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [contractAccepted, setContractAccepted] = useState(false);

  const bookAppointment = async () => {
    setLoading(true);
    if (onlineFirstAppointmentInfo.selectedSlot) {
      const appointmentData = {
        doctorName: onlineFirstAppointmentInfo.doctorName,
        doctorId: onlineFirstAppointmentInfo.doctorId,
        startDate: onlineFirstAppointmentInfo.selectedSlot.start,
        endDate: onlineFirstAppointmentInfo.selectedSlot.end,
        type: DOCTOR_SLOT_SUBTYPE.ONLINE,
        appointmentType: 'firstAppointment',
        comment: 'Von Patient erstellt',
        couponCode,
      };

      const doctorResponse = await UserService.assignDoctorWithType(
        parseInt(userInformation.id, 10),
        parseInt(onlineFirstAppointmentInfo.doctorId, 10),
        'main'
      );
      if (doctorResponse.error) {
        console.error(doctorResponse);
        setLoading(false);

        return;
      }

      appointmentData.doctorName = appointmentData.doctorName.includes('.')
        ? appointmentData.doctorName.split('. ')[1]
        : appointmentData.doctorName;

      AppointmentService.createAppointment(appointmentData).then((res) => {
        if (res.error) {
          setError(true);
          if (res.message.response.status === 401) {
            dispatch(setAuth({ isAuthenticated: false }));
            navigate('/login');
            setError(false);
          }
          console.error(res.message);
          setLoading(false);
        }
        setLoading(false);
        dispatch(loadUserInformation);
        navigate('/first-appointment-success', { state: { appointmentData } });
      });
    }
  };

  if (loading) {
    return <Loading />;
  }

  const appointmentTime = `${new Date(onlineFirstAppointmentInfo.selectedDate).toLocaleString('de-DE', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })}, ${new Date(onlineFirstAppointmentInfo.selectedSlot.start).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })} - ${new Date(onlineFirstAppointmentInfo.selectedSlot.end).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })} Uhr`;

  return (
    <div className={stepsOnlineAppointmentClasses}>
      <Icon icon={'checkCircle'} size={80} color={'#3db384'} />
      <div className={infoTextClasses}>Buchung bestätigen</div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Termin</div>
        <div className={appointmentInfoDescriptionClasses}>Online-Erstgespräch</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Datum / Uhrzeit</div>
        <div className={appointmentInfoDescriptionClasses}>{appointmentTime}</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>Arzt / Ärztin</div>
        <div className={appointmentInfoDescriptionClasses}>{onlineFirstAppointmentInfo.doctorName}</div>
      </div>
      <div className={appointmentInfoContainerClasses}>
        <div className={appointmentInfoLabelClasses}>
          <b>Gutscheincode</b>
        </div>
        <div className={appointmentInfoLabelClasses}>
          <div className={couponCodeFieldClasses}>
            <span>Falls Sie einen Gutscheincode haben, können Sie diesen hier eingeben:</span>
            <InputField onChange={setCouponCode} value={couponCode} />
          </div>
        </div>
      </div>
      <ContractCheckbox
        checked={contractAccepted}
        setChecked={setContractAccepted}
        doctorStamp={onlineFirstAppointmentInfo.doctorStamp}
      />
      <div className={buttonConatinerClasses}>
        <Button disabled={!contractAccepted} onClick={bookAppointment}>
          Termin jetzt buchen
        </Button>
        <a
          className={backButtonClasses}
          onClick={() => {
            const reducerType = {
              type: '[UPDATE]',
              payload: {
                onlineFirstAppointmentIndex: 2,
              },
            };
            setCurrentPage(reducerType);
          }}
        >
          Zurück
        </a>
      </div>
    </div>
  );
}
