import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { QuestionnaireService } from '../../../../services';
import { useAppDispatch } from '../../../../store/hooks';
import { loadUserInformation } from '../../../../store/reducers';
import { Button, Headline, Text } from '../../../atoms';
import { ProgressBar } from '../../../molecules';
import { Checkbox } from '../../../molecules/Checkbox/Checkbox';
import { Header } from '../../../organisms';
import style from './Step8.module.scss';

const cx = classNames.bind(style);
export function Step8(): JSX.Element {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const [informationConsent, setInformationConsent] = useState(false);

  useEffect(() => {
    const personalData = sessionStorage.getItem('questionnaire-personalData');
    if (!personalData) {
      navigation('/questionnaire/step-7');
    }
    const addressData = sessionStorage.getItem('questionnaire-addressData');
    if (!addressData) {
      navigation('/questionnaire/step-7');
    }
  }, []);

  const isButtonDisabled = !informationConsent;

  const clearSessionStorage = () => {
    sessionStorage.removeItem('isAlreadyCannabisPatient');
    sessionStorage.removeItem('questionnaire-step2');
    sessionStorage.removeItem('questionnaire-step3');
    sessionStorage.removeItem('questionnaire-step4');
    sessionStorage.removeItem('questionnaire-step5');
    sessionStorage.removeItem('questionnaire-step6');
    sessionStorage.removeItem('questionnaire-personalData');
    sessionStorage.removeItem('questionnaire-addressData');
  };

  const nextStep = async () => {
    const step1Data = sessionStorage.getItem('isAlreadyCannabisPatient');
    const step2Data = sessionStorage.getItem('questionnaire-step2');
    const step3Data = sessionStorage.getItem('questionnaire-step3');
    const step4Data = sessionStorage.getItem('questionnaire-step4');
    const step5Data = sessionStorage.getItem('questionnaire-step5');
    const step6Data = sessionStorage.getItem('questionnaire-step6');
    const personalData = sessionStorage.getItem('questionnaire-personalData');
    const addressData = sessionStorage.getItem('questionnaire-addressData');

    const stepData = {
      questionnaire: {
        isAlreadyCannabisPatient: step1Data === 'true',
        ...JSON.parse(step2Data || '{}'),
        ...JSON.parse(step3Data || '{}'),
        ...JSON.parse(step4Data || '{}'),
        ...JSON.parse(step5Data || '{}'),
        ...JSON.parse(step6Data || '{}'),
      },
      personalData: JSON.parse(personalData || '{}'),
      addressData: JSON.parse(addressData || '{}'),
    };
    const res = await QuestionnaireService.createQuestionnaire(stepData);
    if (res.error) {
      console.error('Error creating questionnaire');
      console.error(res.message);

      return;
    }

    dispatch(loadUserInformation);
    clearSessionStorage();

    navigation('/dashboard');
  };

  const progressBarContainer = cx({ ProgressBarContainer: true });
  const questionContainerClasses = cx('flex', 'flex-column', 'mb-2');
  const buttonContainerClasses = cx('flex', 'flex-center', 'mt-5', 'mb-4');

  return (
    <>
      <Header barTitle='Daten vervollständigen' showBackButton={true} />
      <div className={progressBarContainer}>
        <ProgressBar count={8} countActive={8} />
      </div>
      <div style={{ margin: '0 auto', maxWidth: '500px' }}>
        <div>
          <Headline level={3}>Angaben bestätigen</Headline>
        </div>

        <div className={questionContainerClasses}>
          <Text>Bitte bestätigen Sie die Richtigkeit Ihrer Angaben.</Text>
        </div>

        <div className={questionContainerClasses}>
          <Checkbox
            checked={informationConsent}
            label='Ich bestätige hiermit, dass meine Angaben richtig sind, und ich aufgrund der angegebenen Diagnose von einem Arzt untersucht worden bin'
            onClick={() => setInformationConsent(!informationConsent)}
          />
        </div>

        <div className={buttonContainerClasses}>
          <Button onClick={nextStep} disabled={isButtonDisabled}>
            Abschließen
          </Button>
        </div>
      </div>
    </>
  );
}
