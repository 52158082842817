import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TermsService from '../../../services/Terms.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuth, setTerms } from '../../../store/reducers';
import type { Term } from '../../../types/terms.type';
import { Loading } from '..';

export function LoadTerms() {
  const navigate = useNavigate();
  const auth = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    TermsService.getActiveTerms().then((res) => {
      if (res.error) {
        console.error(res);

        return;
      }
      const resTerms = res.data as Term[];

      dispatch(setTerms(resTerms.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())));
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (auth.isAuthenticated && (!auth.isTermsAgree || !auth.isPrivacyPolicyAgree)) {
      navigate('/confirm-terms');
    }
  }, [auth]);

  if (loading) {
    return <Loading />;
  }

  return null;
}
