import React, { useEffect } from 'react';
import { isExpired } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import UserService from '../../../services/User.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuth, selectUserInformation, setAuth, setUserInformation } from '../../../store/reducers';
import { Loading } from '../../atoms/Loading/Loading';

type CheckAuthProps = {
  children?: React.ReactNode;
};

export function CheckAuth(props: CheckAuthProps) {
  const dispatch = useAppDispatch();
  const userInformation = useAppSelector(selectUserInformation);
  const authInfo = useAppSelector(selectAuth);
  const navigate = useNavigate();

  const checkAuthenticated = () => {
    const token = localStorage.getItem('JWT');
    const user = localStorage.getItem('User');
    const termAgree = localStorage.getItem('TermsAgree');
    const privacyPolicyAgree = localStorage.getItem('PrivacyPolicyAgree');
    const newsletterAgree = localStorage.getItem('NewsletterAgree');
    if (token && user) {
      const decodedToken = jwtDecode(token) as object;
      if (!isExpired(token) || (decodedToken && !('exp' in decodedToken))) {
        const newAuth = {
          isAuthenticated: true,
          isTermsAgree: termAgree === 'true',
          isPrivacyPolicyAgree: privacyPolicyAgree === 'true',
          isNewsletterAgree: newsletterAgree === 'true',
          name: user,
        };
        dispatch(setAuth(newAuth));

        UserService.information().then((r) => {
          if (r.error) {
            console.error(r.message);
          }
          dispatch(setUserInformation(r.data));
        });
      } else {
        const newAuth = {
          isAuthenticated: false,
          isTermsAgree: termAgree === 'true',
          isPrivacyPolicyAgree: privacyPolicyAgree === 'true',
          isNewsletterAgree: newsletterAgree === 'true',
        };
        dispatch(setAuth(newAuth));

        navigate('/login');
      }
    } else {
      const newAuth = {
        isAuthenticated: false,
        isTermsAgree: termAgree === 'true',
        isPrivacyPolicyAgree: privacyPolicyAgree === 'true',
        isNewsletterAgree: newsletterAgree === 'true',
      };
      dispatch(setAuth(newAuth));
      navigate('/login');
    }
  };

  useEffect(() => {
    checkAuthenticated();
  }, []);

  if (!authInfo.isAuthenticated || userInformation.id === '') {
    return <Loading />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}
