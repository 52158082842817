/* eslint-disable eslint-comments/no-unlimited-disable */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import UserService from '../../services/User.service';
import type { UserInformation } from '../../types/user.types';
import type { RootState } from '../store';
import { initialUserInformation } from './initialState';

const initialState: UserInformation = initialUserInformation;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInformation: (state, action: PayloadAction<UserInformation>) => {
      /* eslint-disable */
      return action.payload;
      /* eslint-enable */
    },
  },
});

export const { setUserInformation } = userSlice.actions;
export const selectUserInformation = (state: RootState) => state.user;

export const loadUserInformation = (dispatch: any) => {
  UserService.information().then((r) => {
    if (r.error) {
      console.error(r.message);
    }

    dispatch(setUserInformation(r.data));
  });
};
