import React, { useState } from 'react';
import { Icon } from '../../atoms';
import classes from './AccordionTile.module.scss';

type AccordionTileProps = {
  title?: string;
  content?: string;
};

export function AccordionTile({ title, content }: AccordionTileProps) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className={classes.AccordionItem}>
      <div className={classes.AccordionTitle} onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>
          {isActive ? (
            <div className={classes.ItemOpenChevron}>
              <Icon icon='chevron' size={16} color={'black'} />
            </div>
          ) : (
            <div className={classes.ItemClosedChevron}>
              <Icon icon='chevron' size={16} color={'black'} />
            </div>
          )}
        </div>
      </div>
      {isActive && <div className={classes.AccordionContent}>{content}</div>}
    </div>
  );
}
