import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Checkbox } from '../../molecules/Checkbox/Checkbox';
import { ContractModal } from '../ContractModal/ContractModal';
import classes from './ContractCheckbox.module.scss';

const cx = classNames.bind(classes);

type ContractCheckboxProps = {
  checked: boolean;
  setChecked: (value: boolean) => void;
  doctorStamp: string;
};

export function ContractCheckbox(props: ContractCheckboxProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalVisible, setModalVisible] = useState(false);
  const [alreadyAccepted, setAlreadyAccepted] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const onCheckboxClick = () => {
    if (props.checked) {
      props.setChecked(false);

      return;
    }

    if (!alreadyAccepted) {
      setModalVisible(true);
    } else {
      props.setChecked(true);
    }
  };

  return (
    <React.Fragment>
      <ContractModal
        doctorStamp={props.doctorStamp}
        setChecked={props.setChecked}
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        setAlreadyAccepted={setAlreadyAccepted}
      />
      <div className={cx('ContractCheckbox')}>
        <Checkbox checked={props.checked} onClick={onCheckboxClick} label='' />
        <div className={cx('Text')}>
          Ich akzeptiere den{' '}
          <span className={cx('Link')} onClick={openModal}>
            Behandlungsvertrag und die Datenschutzerklärung
          </span>
          &nbsp;und nehme zur Kenntnis, dass ich eine Selbstzahlerleistung in Anspruch nehme.
        </div>
      </div>
    </React.Fragment>
  );
}
